import { HostListener, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { BooktechAppService } from './booktech-app.service';

import { format } from 'date-fns'
import formatISO from 'date-fns/formatISO'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import addDays from 'date-fns/addDays'
import isSunday from 'date-fns/isSunday'
import add from 'date-fns/add'
import set from 'date-fns/set'
import intervalToDuration from 'date-fns/intervalToDuration'
import differenceInMonths from 'date-fns/differenceInMonths'
import differenceInDays from 'date-fns/differenceInDays'
import differenceInHours from 'date-fns/differenceInHours'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInSeconds from 'date-fns/differenceInSeconds'


import startOfHour from 'date-fns/startOfHour'
import startOfDay from 'date-fns/startOfDay'
import startOfMonth from 'date-fns/startOfMonth'
import startOfYear from 'date-fns/startOfYear'

import endOfDay from 'date-fns/endOfDay'
import endOfMonth from 'date-fns/endOfMonth'


import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { nb, enUS } from "date-fns/locale";

import { MbscPopup, MbscPopupOptions } from '@mobiscroll/angular-ivy';
import { MiscUtil } from '../util/misc.util';
import { DATAID } from './data.service';
// import { MbscFormOptions } from '@mobiscroll/angular4';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';

import { Subject } from 'rxjs';
// import { debounceTime } from 'rxjs/operators';
import {debounceTime, switchMap, takeUntil, skip } from 'rxjs/operators';

import { BtEvent } from './event.service';
import { Router, NavigationEnd } from '@angular/router';
import { BtContent } from '../model/bt-content';
import { Location } from '@angular/common';

import { DynformControl } from '../model/dymform-control';
import { BtDynamicModalComponent, DynamicModalData } from '../components/ui/bt-dynamic-modal/bt-dynamic-modal.component';


const PADDING = "000000";
const SEPARATORS = {
  "no": { "DECIMAL": ",", "THOUSANDS": ""},
  "en": { "DECIMAL": ".", "THOUSANDS": ""},
};

const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
};

// export const autocomplete = (time:number, selector:any) => (source$:any) =>
//   source$.pipe(
//     debounceTime(time),
//     switchMap((...args: any[]) => 
//       selector(...args)
//         .pipe(
//             takeUntil(
//                 source$
//                     .pipe(
//                         skip(1)
//                     )
//             )
//         )
//     )
//   )

export class UIService {

  public readonly DECIMAL_SEPARATOR: string = ",";
  public readonly THOUSANDS_SEPARATOR: string = " ";

  formSize = {
    lbl: {
      xs: 12,
      sm: 6
    },
    ctrl: {
      xs: 12,
      sm: 10
    }

  }

  resizeSubject = new Subject<any>();
  r = {
    w: -1, //window ? window.innerWidth : -1,
    h: -1, //window ? window.innerHeight : -1,

    xs: true,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
    
    gtexs: true,
    gtesm: false,
    gtemd: false,
    gtelg: false,
    gtexl: false,
    gtexxl: false,
    // ltxs: true,
    ltsm: true,
    ltmd: true,
    ltlg: true,
    ltxl: true,
    ltxxl: true,
    ltexs: true,
    ltesm: true,
    ltemd: true,
    ltelg: true,
    ltexl: true,
    ltexxl: true,

    size: "xs",
    init: false,
  }

  
  //            xs: 0,  sm: 576,  md: 768,  lg: 992,  xl: 1200,  xxl: 1600
  gridFull  = { xs: 24, gutter: [8, 16] };
  gridXl    = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 12, gutter: [8, 16] };
  gridLg    = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12,  xxl: 12,  gutter: [8, 16] };
  gridMd    = { xs: 24, sm: 12, md: 12, lg: 12, xl: 8,  xxl: 6,  gutter: [8, 16] };
  gridMd_1_3 = { xs: 24, sm: 8, md: 8, lg: 6, xl: 4,  xxl: 4,  gutter: [8, 16] };
  gridMd_2_3 = { xs: 24, sm: 16, md: 16, lg: 12, xl: 8,  xxl: 6,  gutter: [8, 16] };
  
  gridSm    = { xs: 24, sm: 12, md: 8,  lg: 8,  xl: 6,  xxl: 4,  gutter: [8, 16] };
  gridXs    = { xs: 12, sm: 8,  md: 8,  lg: 6,  xl: 4,  xxl: 4,  gutter: [8, 16] };
  gridXxs    = { xs: 12, sm: 6,  md: 6,  lg: 4,  xl: 4,  xxl: 2,  gutter: [8, 16] };
  //           xs: 0, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1600

  msPopupSettings: MbscPopupOptions = {
      display: 'anchored',
      buttons: []
  };

  // msFormOptions: MbscFormOptions = { // 
  //   labelStyle: "stacked",
  //   inputStyle: "outline"
  // }

  // label-style="floating" input-style="outline"

  sanitizer = inject(DomSanitizer);
  messageService = inject(NzMessageService);
  notificationService = inject(NzNotificationService);
  modalService = inject(NzModalService);
  router = inject(Router);
  // loc = inject(Location);
  title = inject(Title);


  private history: string[] = [];

  constructor(public bas: BooktechAppService ) {
    if(this.bas.envtest) console.log("UIService.constructor");
   

    if (bas.nossr) {
      this.r.w = window.innerWidth ;
      this.r.h = window.innerHeight ;
    }

    //this.router.navigate([ "/" ], { });

    this.resizeSubject.pipe(
      debounceTime(50)
    ).subscribe((resizeEvent) => {
      this.doResize(resizeEvent);
    });

    this.doResize();


    setTimeout(() => {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if(this.bas.envtest) console.log("UIService.router. adding endEvent: " + event.urlAfterRedirects);
          this.history.push(event.urlAfterRedirects);
        }
      });
    }, 500)

  }

  canGoBack() {
    return this.history.length > 0;
  }
  goBack(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.history.pop();
      
      if (this.history.length > 0) this.history.pop();
      this.bas.LOC.back();
    } else {
      this.router.navigateByUrl("/");
    }
  }
  goHome(): void {
    this.router.navigateByUrl("/");
  }


  onResize(event?:any) {
    // console.log(MiscUtil.getLogText("onResize", true)); 
    this.resizeSubject.next(event);
  }

  doResize(event?:any) {
    // this.innerWidth = window.innerWidth;
    // console.log(MiscUtil.getLogText("doResize", true)); 
    let h =  this.bas.nossr ? window.innerHeight : -1;
    let w = this.bas.nossr ? window.innerWidth : -1;

    var current = this.r;
    var r = MiscUtil.clone(current);

    let b = BREAKPOINTS;

    r.w = w;
    r.h = h;
    r.xs = (w < b.sm);
    r.sm = (w >= b.sm && w < b.md);
    r.md = (w >= b.md && w < b.lg);
    r.lg = (w >= b.lg && w < b.xl);
    r.xl = (w >= b.xl && w < b.xxl);
    r.xxl = (w >= b.xxl);

    // grater then or equal to
    r.gtexs = r.xxl || r.xl || r.lg || r.md || r.sm || r.xs; 
    r.gtesm = r.xxl || r.xl || r.lg || r.md || r.sm; 
    r.gtemd = r.xxl || r.xl || r.lg || r.md; 
    r.gtelg = r.xxl || r.xl || r.lg; 
    r.gtexl = r.xxl || r.xl; 
    r.gtexxl = r.xxl; 

    // less then
    r.ltsm = r.xs; 
    r.ltmd = r.xs || r.sm; 
    r.ltlg = r.xs || r.sm || r.md; 
    r.ltxl = r.xs || r.sm || r.md || r.lg; 
    r.ltxxl = r.xs || r.sm || r.md || r.lg || r.xl; 
    // r.ltxxl = r.xs || r.sm || r.md || r.lg || r.xl || r.xxl; ; 

    // less then or equal to
    r.ltexs = r.xs; 
    r.ltesm = r.xs || r.sm; 
    r.ltemd = r.xs || r.sm || r.md; 
    r.ltelg = r.xs || r.sm || r.md || r.lg; 
    r.ltexl = r.xs || r.sm || r.md || r.lg || r.xl; 
    r.ltexxl = r.xs || r.sm || r.md || r.lg || r.xl || r.xxl; ; 
    
    if (r.xs) r.size = "xs";
    if (r.sm) r.size = "sm";
    if (r.md) r.size = "md";
    if (r.lg) r.size = "lg";
    if (r.xl) r.size = "xl";
    if (r.xxl) r.size = "xxl";


    this.r = r;

    if (current.size != r.size || r.init == false) {

      this.bas.es.trigger(BtEvent.DEVICE_RESIZE, {  prev: current, responsive: r })
    }
 
    this.bas.es.trigger(BtEvent.RESIZE, { prev: current, responsive: r })

    r.init = true;
  }


  info(message:string, durationIsSeconds:number = 4) {
    return this.messageService.info(this.actrans( message ), { nzDuration: durationIsSeconds * 1000 });
  }
  success(message:string, durationIsSeconds:number = 4) {
    return this.messageService.success(this.actrans( message ), { nzDuration: durationIsSeconds * 1000 });
  }
  warning(message:string, durationIsSeconds:number = 4) {
    return this.messageService.warning(this.actrans( message ), { nzDuration: durationIsSeconds * 1000 });
  }
  error(message:string, durationIsSeconds:number = 4) {
    return this.messageService.error(this.actrans( message ), { nzDuration: durationIsSeconds * 1000 });
  }

  modal(type:'error'|'info'|'success', title:string, content:string) {
    if (type == "error") return this.modalError(title, content);
    if (type == "info") return this.modalInfo(title, content);
    if (type == "success") return this.modalSuccess(title, content);

    return this.modalInfo(title, content);
  }


  modalSuccess(title:string, content:string) {
    if (!title) title = 'app.lib.common.success';
    return this.modalService.success({
      nzTitle: this.actrans( title ),
      nzContent: this.actrans( content )
    });
  }

  modalInfo(title:string, content:string) {
    if (!title) title = 'app.lib.common.info';
    return this.modalService.info({
      nzTitle: this.actrans( title ),
      nzContent: this.actrans( content )
    });
  }

  modalError(title:string = "", content:string) {
    if (!title) title = 'app.lib.common.error';

    return this.modalService.error({
      nzTitle: this.actrans( title ),
      nzContent: this.actrans( content )
    });
  }

  modalDynamic(
    controls:DynformControl[], 
    afterClose:((res:any) => void), 
    options:{ 
      title?:string, 
      desc?:string,
      okText?:string,
      cancelText?:string,
      obj?:any
    }) {

    const modal = this.modalService.create<BtDynamicModalComponent, DynamicModalData>({
      nzTitle: options.title ||  this.actrans("app.lib.common.info"), //'Modal Title', options.okText ||
      nzContent: BtDynamicModalComponent,
      // nzViewContainerRef: this.viewContainerRef,
      nzData: {
        controls: controls,
        desc: options.desc,
        obj: options.obj,
      },
      //nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
      nzFooter: [
        {
          label: options.cancelText || this.actrans("app.lib.common.cancel"),
          onClick: componentInstance => {
            componentInstance!.cancel();
          }
        },
        {
          label: options.okText || this.actrans("app.lib.common.ok"),
          type: "primary",
          onClick: componentInstance => {
            componentInstance!.submit();
            // componentInstance!.title = 'title in inner component is changed';
          }
        }
      ]
    });
    // const instance = modal.getContentComponent();
    // modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));
    // Return a result when closed
    modal.afterClose.subscribe(result => {
      if (this.bas.envtest) console.log('modalDynamic[afterClose] The result is:', result)
      afterClose(result || { event: "close" });
    });

  }


  notification(type:string, title:string, desc:string, duration:number = 4) {
    return this.notificationService.create(
      type,
      title,
      desc,
      { 
        nzDuration: duration * 1000
      }
    );
  }

  nzNumberParser = (value: string):string => {  return this.nfparse(value) + ""; }
  nzNumberParser1 = (value: string):string => {  return this.nfparse(value, 1) + ""; }
  nzNumberFormatter = (value: number):string =>  { return this.nf(value);}
  nzNumberFormatter1 = (value: number):string => { return this.nf(value, 1);}

  nf(value: number | string, fractionSize: number = 2): string {
    if (value == undefined) return ""; // undef
    if ((typeof value) === "number" && isNaN(value as number)) return "NaN";
    if (value === "") return "";
    if (typeof value == "string") value = this.nfparse(value);

    let [ integer, fraction = "" ] = (value).toString().split(".");

    if (!this.DECIMAL_SEPARATOR) {
      if(this.bas.envtest) console.log("this.DECIMAL_SEPARATOR == null, this: " + this);
    }
   
    fraction = fractionSize > 0
      ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
      : "";

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);

    return integer + fraction;
  }

  nfparse(value: string, fractionSize: number = 2): number {
    let val = (value || "") + "";
    let [ integer, fraction = "" ] = val.split(this.DECIMAL_SEPARATOR);

    integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, "g"), "");

    fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
      ? "." + (fraction + PADDING).substring(0, fractionSize)
      : "";

    return parseFloat(integer + fraction);
  }

  
  cbtrans(message:any):string {
    if (typeof message == "string") return message;

    let lang =  this.bas.ds.findLang() || this.bas.ds.lang.code || "no";
    // MiscUtil.l("cbtrans, this.lang: " + this.lang + ", message: ", message);
    var trans = message.texts[lang + "Trust"];
    if (!trans) trans = message.texts[lang];
    if (!trans) {
      var altLang = lang == "no" ? "en" : "no";
      trans = message.texts[altLang + "Trust"];
      if (!trans) trans = message.texts[altLang];
    }
    return trans;
  }

  actrans(key:string, input:any|any[] = [ ], safe:boolean = false, fallback?:string): any { //(string | SafeHtml)
    let ac = this.bas.ds.config.appConfig;
    let keystring = key; // this.bas.envprod ? "" : "" + key; // this.bas.envprod ? "" : "" + key;

    let opts = Array.isArray(input) ? {
      params: input,
      safe: safe,
      fallback: fallback
    } : input;

    let params = opts.params || [];
    safe = opts.safe;
    fallback = opts.fallback;

    let lang =  this.bas.ds.findLang() || this.bas.ds.lang.code || "no";
    if (!fallback) fallback = keystring;

    // console.log("key: " + key 
    //   + ", lang: " + lang
    //   + ", ac.m: " + (ac ? ac.messagesTrust : "null")
    //   + "; ac: ", ac);
    
    if (!ac) return fallback;

    var messages = safe ? ac.messagesTrust : ac.messages;
    if (!messages) return fallback;

    // console.log("messages: ", messages); 

    var texts = messages[key];

    // console.log("texts: ", texts); 

 
    if (!texts) return fallback;
    var trans = texts[lang];
    if (!trans) {
      var altLang = lang == "no" ? "en" : "no";
      trans = texts[altLang];
    }
    
    if (params.length) {
      for (let idx = 0; idx < params.length; idx++) {
        let value = params[idx];
        let text:string = safe ? trans.changingThisBreaksApplicationSecurity : trans;
        text = text.replace(new RegExp("\\{"+idx+"\\}", "g"), value);
        trans = safe ?  this.sanitizer.bypassSecurityTrustHtml(text) : text;
      }
    }

    if (opts.lowercase) {
      let text:string = safe ? trans.changingThisBreaksApplicationSecurity : trans;
      text = text.toLowerCase();
      trans = safe ?  this.sanitizer.bypassSecurityTrustHtml(text) : text;

    }

    // if (this.bas.envtest) console.log("key: " + key + ", lang: "+lang+", trans: '" + trans + "'");

    return trans;
  }
  messagetrans(message:any, params:any[] = [ ], safe:boolean = false, fallback?:string): any {
    
    let lang = this.bas.ds.lang.code || "no";
    if (!fallback) fallback = "";

    if (!message || !message.texts) return fallback;
    if (safe) message = this.bas.ds.getMessageTrust(message);
    var texts = message.texts;

    if (!texts) return fallback;
    var trans = texts[lang];
    if (!trans) {
      var altLang = lang == "no" ? "en" : "no";
      trans = texts[altLang];
    }
    // console.log("trans: '" + trans + "'");
    
    if (params.length) {
      for (let idx = 0; idx < params.length; idx++) {
        let value = params[idx];
        let text:string = safe ? trans.changingThisBreaksApplicationSecurity : trans;
        text = text.replace(new RegExp("\\{"+idx+"\\}", "g"), value);
        trans = safe ?  this.sanitizer.bypassSecurityTrustHtml(text) : text;
      }
    }

    return trans;
  }

  getRouterPrefixCommands():any[] {
    return this.getRouterPrefix().split("/");
  }
  getRouterPrefix(lang?:string, cid?:string):string {
    if (lang === undefined) lang =  this.bas.ds.findLang() || "no";
    if (this.bas.settings.appId == "cbsite" ) {
      let isCustomDomain = this.isCustomHostname(); 
      if (!cid) cid = this.bas.ds.findCid('ui.service.getRouterPrefix.cbsite');
      // if(this.bas.envtest) console.log("getRouterPrefix, isCustomDomain: " + isCustomDomain + ", cid: " + cid + ", hn: " + (this.bas.nossr ? window.location.hostname : "cloudbooking.io"));
      return  (!isCustomDomain && cid ? "/booking/" + cid : "") + "/" + lang;
    }


    //let cid = this.bas.ds.getValue(DATAID.APP_URL_PARAMS).cid;

    let pn = this.bas.nossr ? window.location.pathname : "";
    return pn.startsWith("/api/") ? "/api/" + (this.bas.ds.findCid('ui.service.getRouterPrefix') || "_") : "";
  }
  isCustomHostname(hn?:string) {
    let isCustomDomain = false;
    if (this.bas.settings.appId == "cbsite" ) {
      if (!hn) hn = this.bas.nossr ? window.location.hostname : "cloudbooking.no";
      isCustomDomain = (this.bas.envprod  )  // && hn.indexOf(".cloudbooking.no") > 0 | Prod er alltid "custom"
        || (this.bas.envdev && hn.indexOf("192.168.1.") < 0) 
        || (this.bas.envtest && !this.bas.envdev && hn.indexOf("cloudbooking.io") < 0) 
      
      ;
    }
    return isCustomDomain;
  }

  devhost() {
    return this.bas.nossr && window.location.hostname.indexOf("192.168.1.") >= 0 ? "https://" + window.location.hostname : "";

  }



  trustUrl(url:string):SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  dateToIsoString(date:Date):string {
    return formatISO(date); //, 'yyyy-MM-dd[T]HH:mm:ssZZ')
  }
  isoStringToDate(dateString:string):Date {
    return parseISO(dateString); 
  }

  missingImg() {
    return  this.bas.ws.getBaseUrlStatic() + '/img/misc/missing_image_400x300.png';
  }

  dateRangeToDates(range:string):Date[] {
    
    let parts = range && range != "" && range != "_" ? range.split("_") : [];

    let dates:Date[] = [];
    let firstDate = "";
    for (let part of parts) {
      let subParts = part.split(" ");
      let date = subParts.length > 0 ? subParts[0] : "";
      let time = subParts.length > 1 ? subParts[1] : "";

      if (!firstDate) firstDate = date;
      let isEnd = dates.length != 0;

      let dt = MiscUtil.setDateAsString(date);
      if (dt) dates.push(dt);

    }

    return dates;
  }

  dateToString(date:Date):string {
    if (date == undefined) return "";
    return format(date, 'yyyy-MM-dd')
  }
  stringToDate(date:string):Date|undefined {
    return parse(date, 'yyyy-MM-dd', new Date());
  }

  dateRangeToString(dates:Date[]):string {
    if (dates == undefined || dates.length < 1) return "_";
    let from = dates[0];
    let to =  dates[1];

    return this.dateToString(from) + "_" +  this.dateToString(to);
  }
  dateTimeRangeToString(dates:Date[]|string[]):string {
    if (dates == undefined || dates.length < 1) return "_";
    let from = dates[0];
    let to =  dates[1];

    let dFrom = from instanceof Date ? from : this.dateParseIso(from);
    let dTo = to instanceof Date ? to : this.dateParseIso(to);

    return format(dFrom, 'yyyy-MM-dd HH:mm') + "_" +  format(dTo, 'yyyy-MM-dd HH:mm');
  }


  dateFormat(date:Date, dateFormat:string = 'yyyy-MM-dd') {
    if (date == undefined) return undefined;
    try {
      if (dateFormat == "Ps") { // "Long localized date" (short) 
        let lang =  this.bas.ds.findLang() || this.bas.ds.lang.code || "no";

        dateFormat = lang === "no" ?  "dd.MM.yy" : "MM/dd/yy";
      }
      return format(date, dateFormat, { locale: this.dateFnsLocale() });
    }catch (err) {
      console.log("dateFormat, err: ", err);
      return "";
    }

  }
  dateStringFormat(date:string, dateFormat:string = 'yyyy-MM-dd') {
    if (date == undefined) return date;
    let dt = MiscUtil.setDateAsString(date);

    if (!dt) return date;
    return this.dateFormat(dt, dateFormat);
  }
  dateRangeFormat(range:string, dateFormat:string = 'yyyy-MM-dd') {
    if (range == undefined) return undefined;


    try {
      let dates = this.dateRangeToDates(range);
      if (!dates || dates.length != 2) return "";

      return format(dates[0], dateFormat) + " - " + format(dates[1], dateFormat);
    }catch (err) {
      console.log("dateRangeFormat, err: ", err);
      return "";
    }

  }

  timeFormat(date:Date, timeFormat:string = 'HH:mm') {
    if (date == undefined) return undefined;
    return format(date, timeFormat);
  }
  dateTimeFormat(date:Date, timeFormat:string = 'yyyy-MM-dd HH:mm') {
    if (date == undefined) return undefined;
    return format(date, timeFormat);
  }

  dateParse(dateString:string, dateFormat?:string, refDate:Date = new Date()) {
    if (dateFormat == undefined) {
      if (dateString.indexOf(" ") && dateString.length == 16) dateFormat = 'yyyy-MM-dd HH:mm';
      else dateFormat = 'yyyy-MM-dd';
    }
    return parse(dateString, dateFormat, refDate);
  }
  dateTimeParse(dateString:string, dateFormat:string = 'yyyy-MM-dd HH:mm', refDate:Date = new Date()) {
    return parse(dateString, dateFormat, refDate);
  }
  timeParse(timeString:string, dateFormat:string = 'HH:mm', refDate:Date = new Date()) {
    return parse(timeString, dateFormat, refDate);
  }

  dateFormatIso(date:Date):string {
    return formatISO(date); //, 'yyyy-MM-dd[T]HH:mm:ssZZ')
  }
  dateParseIso(dateString:string):Date {
    return parseISO(dateString); 
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }


  dateAdd(date:Date = new Date(), duration:Duration) {
    return add(date, duration);
  }
  dateSet(date:Date = new Date(), values:Duration) {
    return set(date, values);
  }

  dateStringAdd(dateString:string, duration:Duration) {
    let date = this.dateParse(dateString);
    let res = add(date, duration);
    return this.dateFormat(res);
  }
  dateStringSet(dateString:string, values:Duration) {
    let date = this.dateParse(dateString);
    let res = set(date, values);
    return this.dateFormat(res);

    // return set(date, values);
  }

  //  set(new Date(2014, 8, 20), { year: 2015, month: 9, date: 20 })

  differenceInDays(from:Date = new Date(), to:Date = new Date()) {
    return differenceInDays(from, to);
  }

  dateDifference(from:Date = new Date(), to:Date = new Date(), field:string) {
    
    switch (field) {
      case "second": 
      case "seconds": 
        return differenceInSeconds(from, to);
      case "minute": 
      case "minutes":     
        return differenceInMinutes(from, to);
      case "hour":
      case "hours":
          return differenceInHours(from, to);
      case "day": 
      case "days": 
        return differenceInDays(from, to);
      case "month": 
      case "months": 
        return differenceInMonths(from, to);
    }

    return -1;
  }

  intervalToDuration(interval:Interval) {
    return intervalToDuration(interval);
  }
  dateStartOf(date:Date = new Date(), field:string): Date {

    switch (field) {
      case "hour":  return startOfHour(date);
      case "day":  return startOfDay(date);
      case "month":  return startOfMonth(date);
      case "year":  return startOfYear(date);
    }

    return date;
  }
  dateEndOf(date:Date = new Date(), field:string): Date {

    switch (field) {
      case "day":  return endOfDay(date);
      case "month":  return endOfMonth(date);
    }

    return date;
  }

  dateFnsLocale() {
    
    let lang =  this.bas.ds.findLang() || this.bas.ds.lang.code || "no";
    return lang === "no" ? nb : enUS;
  }

  formatDistanceToNow(date:Date = new Date()): string {
 
    return formatDistanceToNow(date, { locale: this.dateFnsLocale() });
  }
  

  // ----------
  
  fixNewline(text:any) {
    return text && typeof text === "string" ? text.replace(/\n/g, "<br/>") : text;
  }

  setTitle(title:string) {
    this.title.setTitle(title);
  }
  getTitle() {
    return this.title.getTitle();
  }

  getAllDatesBwtweenDates(from:Date, to:Date): Date[] {
    let dates:Date[] = [];

    let current = from;

    while (current.getTime() < to.getTime()) {
      dates.push(current);
      current = addDays(current, 1);
    }
    //console.log("from: " + from + ", to: " + to + ", current: " + current);

    return dates;
  }


  toContentList(source:any, paths:any, options:any = {}):BtContent[] {
    let rows:BtContent[] = [];
    options = options || { };

    for (let path of Object.keys(paths)) {
      let value:any = undefined;
      if (MiscUtil.hasOwnNestedProperty(source, path)) {
        value = MiscUtil.getOwnNestedProperty(source, path);
      }
      let obj = paths[path];
      if (obj.value !== undefined) value = obj.value; 

      if (value === undefined) {
        if(this.bas.envtest) console.log("value === undefined, path: " + path + ", source: ", source);
        continue;
      }

      if (typeof obj === "string") obj = { label: obj };

      let type = obj.type || "string";
      if (type == "double") value = this.bas.ui.nf(value);
      else if (type == "boolean") value = this.actrans("common." + (!!value));
      else if (type == "json") {

        value =  this.bas.us.pretty(value);
      }
      else if (type == "email" && value) value = '<a href="mailto:'+value+'">'+value+'</a>';
      else if (type == "telephone" && value) {
     
        let label = value + "";
        let val = label.replace(/ /g, "");

        // if(this.bas.envtest) console.log("value: " +value + ", val: " + val + ", label: " + label);
        if (val.length == 8 || val.startsWith("+")) {
          if (val.length == 8) {
            val = "+47" + val;
          }

          value = '<a href="tel:'+val+'">'+label+'</a>';
        }
      }

      if (options.skipEmpty && (value === "" || value === 0)) continue;

      obj.value = value; 

      rows.push(new BtContent(obj));
    }

    return rows;
  }

  toContentListObject(object:any, options:any = {}):BtContent[] {

    let rows:BtContent[] = [];
    options = options || { };

    for (let key of Object.keys(object)) {
      let obj = object[key];
      
      let value:any = undefined;

      if (options.inputValuesGuestList && obj?.list?.length) {
        for (let ivg of obj.list) {
          // let label = value + "";
          // let val = label.replace(/ /g, "");
          rows.push(new BtContent(ivg));

        }
        continue;
      }

      if (typeof obj === "string") {
        value = obj;
        obj = { label: key };

      }

      let type = obj.type || "string";

      if (type == "double") value = this.bas.ui.nf(value);
      else if (type == "email" && value) value = '<a href="mailto:'+value+'">'+value+'</a>';
      else if (type == "telephone" && value) {
        //TODO: ta bort mellomrom og legge på +47 forran om nødvendig. Hva med kommaseparert?
        let label = value + "";
        let val = label.replace(/ /g, "");

        if(this.bas.envtest) console.log("value: " +value + ", val: " + val + ", label: " + label);
        if (val.length == 8 || val.startsWith("+")) {
          if (val.length == 8) {
            val = "+47" + val;
          }

          value = '<a href="tel:'+val+'">'+label+'</a>';
        }

        
      }  

      if (options.skipEmpty && (value === "" || value === 0)) continue;

      obj.value = value; 

      rows.push(new BtContent(obj));
    }
    return rows;
  }

  getTelephoneHref(value:any) {
    if (!value || typeof value != "string") return "";

    let val = value.replace(/ /g, "");

    // if(this.bas.envtest) console.log("value: " +value + ", val: " + val + ", label: " + label);
    if (val.length == 8 || val.startsWith("+")) {
      if (val.length == 8) {
        val = "+47" + val;
      }

      value = val;
    }

    return "tel:" + val;

  }


}
